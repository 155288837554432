import React from "react"

import Layout from "../components/layout"
import SEO from "../components/seo"
import PageTitle from "../components/page-title"

function Demo() {
  return (
    <Layout>
      <SEO title="Nickelled Demo" />
      <PageTitle
        preheader="Watch The Tour"
        title="Nickelled Video Demo"
        subtitle="A brief video tour of what Nickelled can do."
      ></PageTitle>

      <div class="pb-24 grid grid-cols-1 items-center">
        <iframe
          width="854"
          height="480"
          src="https://www.youtube.com/embed/8t7qIjW7Llo"
          title="YouTube video player"
          frameborder="0"
          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
          allowfullscreen
          class=" justify-self-center"
        ></iframe>
      </div>
    </Layout>
  )
}

export default Demo
